import React, { useState, useEffect } from "react";
import lettersData from "./data/letters.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import "./LettersPage.css";

const LettersPage = () => {
  const [letters, setLetters] = useState([]);
  const [openedLetter, setOpenedLetter] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    setLetters(lettersData);
  }, []);

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const generateCalendarDays = () => {
    const days = [];
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const numDays = daysInMonth(currentMonth, currentYear);
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`blank-${i}`} className="calendar-day blank"></div>);
    }

    for (let day = 1; day <= numDays; day++) {
      const formattedDate = `${currentYear}-${(currentMonth + 1)
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      const letter = letters.find((letter) => letter.date === formattedDate);

      days.push(
        <div
          key={day}
          className={`calendar-day ${letter ? "has-letter" : ""}`}
          onClick={() => letter && setOpenedLetter(letter)}
        >
          {day}
          {letter && (
            <FontAwesomeIcon
              icon={faHeart}
              className="calendar-heart"
              style={{ color: letter.heartColor }}
            />
          )}
        </div>
      );
    }

    return days;
  };

  const closeModal = () => {
    setOpenedLetter(null);
  };

  const goToPreviousMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    setCurrentDate(newDate);
  };

  const goToNextMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    setCurrentDate(newDate);
  };

  return (
    <div className="letters-container">
      <div className="calendar-header">
        <button onClick={goToPreviousMonth}>&lt;</button>
        <h2>
          {currentDate.toLocaleString("default", { month: "long" })}{" "}
          {currentDate.getFullYear()}
        </h2>
        <button onClick={goToNextMonth}>&gt;</button>
      </div>
      <div className="calendar-grid">{generateCalendarDays()}</div>

      {openedLetter && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <h2>Carta para {openedLetter.to}</h2>
              <p className="letter-date">
                Fecha: {openedLetter.date}
                <FontAwesomeIcon
                  icon={faHeart}
                  className="letter-heart"
                  style={{ color: openedLetter.heartColor }}
                />
              </p>
              <p>{openedLetter.content}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LettersPage;
