import "./LettersPage.css";
import LettersPage from "./LettersPage";

function App() {
  return (
    <div className="App">
      <LettersPage />
    </div>
  );
}

export default App;
